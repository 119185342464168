import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/protectedRoute";
import './App.css';
import Login from "./pages/Login";
import Upload from './pages/Upload';
import UploadHistory from './pages/UploadHistory'
import TeacherDashboard from './pages/TeacherDashboard';
import GradedEssay from './pages/GradedEssay';
import SignUp from './pages/SignUp';
import ForgetPassword from './pages/ForgetPassword';
import PricingSection from "./pages/PricingSection";
import VerifyUserPage from "./pages/VerifyUser"
import { UserProvider } from './UserContext';

function App() {
  return (
    <UserProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/SignUp" element={<SignUp/>}/>
          <Route path="/verify" element={<VerifyUserPage/>}/>
          <Route path="/ForgotPassword" element={<ForgetPassword/>}/>
          <Route path="/PricingSection" element={<PricingSection/>}/>
          <Route path="/BulkUpload" element={<ProtectedRoute><Upload /></ProtectedRoute>} />
          <Route path="/TeacherDashboard" element={<ProtectedRoute><TeacherDashboard /></ProtectedRoute>} />
          <Route path="/GradedEssay/:id" element={<ProtectedRoute><GradedEssay /></ProtectedRoute>} />
          <Route path="/Upload-History" element={<ProtectedRoute><UploadHistory /></ProtectedRoute>} />
        </Routes>
    </UserProvider>
  );
}

export default App;
