import axios from "axios";

const HOST_API = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL + "/api"
  : "http://localhost:4000/api";
const uid = localStorage.getItem("uid");

export const uploadFiles = async (formData) => {
  try {
    const response = await fetch(`${HOST_API}/GoogleVision/upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("File upload failed");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in uploadFiles:", error);
    throw error;
  }
};

export const fileUploadPageNumber = async ({ startDate, endDate, search }) => {
  try {
    const response = await fetch(`${HOST_API}/documents/pagesNumber`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${uid}`,
      },
      body: JSON.stringify({
        filter: { startDate, endDate, search },
      }),
    });

    if (!response.ok) {
      throw new Error("Page Number Retrieval failed");
    }
    return await response.json();
  } catch (error) {
    console.error("Error in fileUploadPageNumber:", error);
    throw error;
  }
};

export const documentList = async (page, { startDate, endDate, search, uid }) => {
  if (!search) {
    search = "";
  }
  try {
    const response = await fetch(`${HOST_API}/documents/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: uid,
        page: page,
        filter: { startDate, endDate, search },
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in retrieving document list", error);
    throw error;
  }
};

export const documentDetail = async (id) => {
  try {
    const response = await fetch(`${HOST_API}/documents/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in retrieving document list", error);
    throw error;
  }
};

export const documentDelete = async (id) => {
  try {
    const response = await fetch(`${HOST_API}/documents/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in deleting document", error);
    throw error;
  }
};

export const grading = async (payload) => {
  try {
    const response = await axios.post(`${HOST_API}/OpenAI/grade`, payload);

    // if (!response.ok) {
    //   throw new Error('Grading failed!');
    // }

    return response.data;
  } catch (error) {
    console.error("Error when grading:", error);
    throw error;
  }
};

export const updateDocument = async (id, payload) => {
  try {
    const response = await axios.put(`${HOST_API}/documents/${id}`, payload);

    return response.data;
  } catch (error) {
    console.error("Error when grading:", error);
    throw error;
  }
};

export const updateScoresDocument = async (id, payload) => {
  try {
    const response = await axios.patch(`${HOST_API}/documents/update`, payload);
    return response.data;
  } catch (error) {
    console.error("Error updating document:", error);
    throw error;
  }
};

export const updateGradedDocument = async (id, formData) => {
  try {
    const response = await fetch(`${HOST_API}/documents/graded/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
      body: formData,
    });

    return response.data;
  } catch (error) {
    console.error("Error when grading:", error);
    throw error;
  }
};

export const restoreDocument = async (id) => {
  try {
    const response = await fetch(`${HOST_API}/documents/restore/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
    });

    return await response.json();
  } catch (error) {
    console.error("Error when grading:", error);
    throw error;
  }
};

export const numberEssayGraded = async (id) => {
  try {
    const response = await fetch(`${HOST_API}/Dashboard/numberEssayGraded`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
    });

    if (!response.ok) {
      throw new Error("File upload failed");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in uploadFiles:", error);
    throw error;
  }
};

export const marksEssayGraded = async (id) => {
  try {
    const response = await fetch(`${HOST_API}/Dashboard/marksEssayGraded`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
    });

    if (!response.ok) {
      throw new Error("File upload failed");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in uploadFiles:", error);
    throw error;
  }
};

export const sendVerificationEmail = async(email, fullName) => {
  try {
    const response = await fetch(`${HOST_API}/EmailVerify/sendVerificationEmail`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${uid}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        fullName: fullName,
      }),
    });
    return true;
  } catch (error) {
    console.error("Error in Send Verification Email:", error);
    throw error;
  }
}

export const verifyUserAccount = async(hashNameandEmail) => {
  try {
    const response = await fetch(`${HOST_API}/EmailVerify/verifyUser`, {
      method: "POST",
      headers: {
        Authorization:  `Bearer ${uid}`,
      },
      body: {
        hashedNameandEmail: hashNameandEmail,
      }
    });

    if (!response.ok) {
      throw new Error("Verification of User Failed");
    }
    return true;
  } catch (error) {
    console.error("Error in Verification of User:", error);
    throw error;
  }
}
