import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createUserWithEmailAndPassword} from "firebase/auth";
import { auth } from "../firebase";
import { sendVerificationEmail } from "../API/api";
import "./SignUp.css";
import logo from "../assets/remarkably logo.png";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Signup = () => {
  const userRef = useRef();
  const errRef = useRef();

  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [alreadySignedUp, setAlreadySignedUp] = useState(false);
  const [sentEmailMsg, setSentEmailMsg] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    const result = emailRegex.test(email);
    setValidEmail(result);
  }, [email]);

  useEffect(() => {
    const result = passwordRegex.test(password);
    setValidPassword(result);
    const match = password === confirmPassword;
    setValidConfirmPassword(match);
  }, [password, confirmPassword]);

  useEffect(() => {
    setErrMsg("");
  }, [email, password, confirmPassword]);
  const handleSubmit = (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        sendVerificationEmail(email, name);
        setSentEmailMsg(true);
        setAlreadySignedUp(true);
      })
      .catch((error) => {
        console.log(error)
        setErrMsg("Failed to create an account, Email already registered!");
      });
  };

  let notificationMsg = "An Email has been sent to your Email Address!"

  return (
        <div className="signup-page-container">
          <img src={logo} alt="Logo" className="signup-logo" />
          <div className="auth-form-container">
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
              {errMsg}
            </p>
            <p className={sentEmailMsg ? "notification" : "offscreen"} aria-live="assertive">
              {notificationMsg}
            </p>
            <h2>Sign Up</h2>
            <form className="signup-form" onSubmit={handleSubmit}>
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                id="fullName"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setName(e.target.value)}
                required
              />

              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                aria-invalid={validEmail ? "false" : "true"}
                aria-describedby="emailnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
              />
              <p
                id="emailnote"
                className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                Enter a valid Email Address.
              </p>

              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                required
                aria-invalid={validPassword ? "false" : "true"}
                aria-describedby="pwdnote"
                onFocus={() => setPasswordFocus(true)}
                onBlur={() => setPasswordFocus(false)}
              />
              <p id="pwdnote" className={passwordFocus && !validPassword ? "instructions" : "offscreen"}>
                <FontAwesomeIcon icon={faInfoCircle} />
                Must include uppercase, lowercase, a number, and a special character.
              </p>

              <label htmlFor="confirm_password">Confirm Password</label>
              <input
                type="password"
                id="confirm_password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                aria-invalid={validConfirmPassword ? "false" : "true"}
                aria-describedby="confirmnote"
                onFocus={() => setConfirmPasswordFocus(true)}
                onBlur={() => setConfirmPasswordFocus(false)}
              />
              <p
                id="confirmnote"
                className={confirmPasswordFocus && !validConfirmPassword ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                Must match the password.
              </p>

              <button
                className="signup-button"
                disabled={!validEmail || !validPassword || !validConfirmPassword || alreadySignedUp}
              >
                Sign Up
              </button>
            </form>
          </div>
          <Link to="/login" className="link-btn">
            Already have an account? Login here.
          </Link>
        </div>
      )}

export default Signup;
