import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../firebase.js';
import './ForgetPassword.css';
import logo from '../assets/remarkably logo.png';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function ForgetPassword() {

  const userRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    const result = emailRegex.test(email);
    setValidEmail(result);
  }, [email]);

  const handleSubmit = (e) => {
    e.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert("A password reset link has been sent to your registered email");
      })
      .catch((error) => {
        console.error(error.code, error.message);
        alert("Error sending reset email. Please try again.");
      });
  };

  return (
    <div className="forget-page-container">
      <img src={logo} alt="Logo" className="forget-password-logo" />
      <div className="login-form">
        <h2 className='forgot-password-title'>Forgot Your Password</h2>
        <p className='page-info'>Please enter an email address you would like your password reset information sent to</p>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">
            Email Address
            {validEmail && (
              <span className="valid">
                <FontAwesomeIcon icon={faCheck} />
              </span>
            )}
          </label>

          <input
            type="email"
            id="email"
            ref={userRef}
            autoComplete="off"
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="name@example.com"
            aria-invalid={validEmail ? "false" : "true"}
            aria-describedby="uidnote"
            onFocus={() => setEmailFocus(true)}
            onBlur={() => setEmailFocus(false)}
          />

          <p
            id="uidnote"
            className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
            Enter a valid Email Address.
          </p>

          <button
            type="submit"
            className="reset-email-button"
            disabled={!validEmail}
          >
            Send reset email
          </button>
          <div className='link-btn-container'>
            <Link to="/login" className="link-btn">
              Back to Login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgetPassword;
