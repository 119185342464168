import React, { useEffect, useState } from "react";
import "./Login.css";
import logo from '../assets/remarkably logo.png';
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        localStorage.setItem(
          "name",
          userCredential?.user?.displayName || "No Name"
        );
        localStorage.setItem("email", userCredential?.user?.email);
        localStorage.setItem("accessToken", userCredential?.user?.accessToken);
        localStorage.setItem("uid", userCredential?.user?.uid);
        navigate("/BulkUpload");
      })
      .catch((error) => {
        console.log(error);
        setError("Wrong Username or password");
      });
  };

  const resetToken = () => {
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("uid");
  };

  useEffect(() => {
    resetToken();
  }, []);

  return (
    <form onSubmit={handleSubmit} className="login-form">
      <img src={logo} alt="Logo" className="login-logo" />
      <input
        className="loginInputField"
        type="text"
        placeholder="Username"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        className="loginInputField"
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      
      <button className="login-button" type="submit">
        Log In
      </button>

      {error && <p className="error-message">{error}</p>}

      <div className="login-links">
        <a href="/ForgotPassword" className="forgot-password">Forgot password?</a>
        {/* <a href="/PricingSection" className="signup-link">View Pricing</a> */}
        <a href="/SignUp" className="signup-link">Sign up</a>
      </div>
    </form>
  );
}

export default Login;
