import axios from "axios";

const HOST_API = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL + "/api"
  : "http://localhost:4000/api";
const uid = localStorage.getItem("uid");

export const uploadFiles = async (formData) => {
  try {
    const response = await fetch(`${HOST_API}/GoogleVision/upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("File upload failed");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in uploadFiles:", error);
  }
};

export const fileUploadPageNumber = async ({ startDate, endDate, search }) => {
  try {
    const response = await fetch(`${HOST_API}/documents/pagesNumber`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${uid}`,
      },
      body: JSON.stringify({
        filter: { startDate, endDate, search },
      }),
    });

    if (!response.ok) {
      throw new Error("Page Number Retrieval failed");
    }
    return await response.json();
  } catch (error) {
    console.error("Error in fileUploadPageNumber:", error);
  }
};

export const documentList = async (
  page,
  { startDate, endDate, search, uid }
) => {
  if (!search) {
    search = "";
  }
  try {
    const response = await fetch(`${HOST_API}/documents/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${uid}`,
      },
      body: JSON.stringify({
        userId: uid,
        page: page,
        filter: { startDate, endDate, search },
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in retrieving document list", error);
  }
};

export const documentDetail = async (id) => {
  try {
    const response = await fetch(`${HOST_API}/documents/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in retrieving document list", error);
  }
};

export const documentDelete = async (id) => {
  try {
    const response = await fetch(`${HOST_API}/documents/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in deleting document", error);
  }
};

export const grading = async (payload) => {
  try {
    const response = await axios.post(`${HOST_API}/OpenAI/grade`, payload);

    // if (!response.ok) {
    //   throw new Error('Grading failed!');
    // }

    return response.data;
  } catch (error) {
    console.error("Error when grading:", error);
  }
};

export const updateDocument = async (id, payload) => {
  try {
    const response = await fetch(`${HOST_API}/documents/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${uid}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    return response.data;
  } catch (error) {
    console.error("Error when grading:", error);
  }
};

export const updateDocumentCanvas = async (id, payload) => {
  try {
    const response = await fetch(`${HOST_API}/documentCanvas/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${uid}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    return response.data;
  } catch (error) {
    console.error("Error when grading:", error);
  }
};

export const getDocumentCanvas = async (id) => {
  try {
    const response = await fetch(`${HOST_API}/documentCanvas/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in retrieving document list", error);
  }
};

export const updateScoresDocument = async (id, payload) => {
  try {
    const response = await axios.patch(`${HOST_API}/documents/update`, payload);
    return response.data;
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

export const updateGradedDocument = async (id, formData) => {
  try {
    const response = await fetch(`${HOST_API}/documents/graded/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
      body: formData,
    });

    return response.data;
  } catch (error) {
    console.error("Error when grading:", error);
  }
};

export const restoreDocument = async (id) => {
  try {
    const response = await fetch(`${HOST_API}/documents/restore/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
    });

    return await response.json();
  } catch (error) {
    console.error("Error when grading:", error);
  }
};

export const numberEssayGraded = async (id) => {
  try {
    const response = await fetch(`${HOST_API}/Dashboard/numberEssayGraded`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
    });

    if (!response.ok) {
      throw new Error("File upload failed");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in uploadFiles:", error);
  }
};

export const marksEssayGraded = async (id) => {
  try {
    const response = await fetch(`${HOST_API}/Dashboard/marksEssayGraded`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
    });

    if (!response.ok) {
      throw new Error("File upload failed");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in uploadFiles:", error);
  }
};

// questions api

export const questionList = async (
  page,
  { startDate, endDate, search, uid }
) => {
  if (!search) {
    search = "";
  }
  try {
    const response = await fetch(`${HOST_API}/question/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${uid}`,
      },
      body: JSON.stringify({
        userId: uid,
        page: page,
        filter: { startDate, endDate, search },
      }),
    });
    return await response.json();
  } catch (error) {
    console.error("Error in retrieving document list", error);
  }
};

export const uploadNewQuestion = async (uid, formData) => {
  try {
    const response = await fetch(`${HOST_API}/question/upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
      body: formData,
      redirect: "follow",
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in retrieving document list", error);
  }
};

export const getQuestion = async (uid, id) => {
  try {
    const response = await fetch(`${HOST_API}/question/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${uid}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error in deleting question", error);
  }
};

export const updateQuestion = async (uid, formData) => {
  try {
    const response = await fetch(`${HOST_API}/question/update`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in retrieving document list", error);
  }
};

export const deleteQuestion = async (id) => {
  try {
    const response = await fetch(`${HOST_API}/question/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${uid}`,
      },
      body: JSON.stringify({
        _id: id,
        topic: "_",
        instruction: "_",
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in deleting question", error);
  }
};

export const sendVerificationEmail = async (email, fullName) => {
  try {
    const response = await fetch(
      `${HOST_API}/EmailVerify/sendVerificationEmail`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${uid}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          fullName: fullName,
        }),
      }
    );
    return response;
  } catch (error) {
    console.error("Error in Send Verification Email:", error);
  }
};

export const verifyUserAccount = async (hashNameandEmail) => {
  try {
    const response = await fetch(`${HOST_API}/EmailVerify/verifyUser`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${uid}`,
      },
      body: {
        hashedNameandEmail: hashNameandEmail,
      },
    });

    if (!response.ok) {
      throw new Error("Verification of User Failed");
    }
    return true;
  } catch (error) {
    console.error("Error in Verification of User:", error);
  }
};

export const appendFeedbackPDF = async (feedback, documentId) => {
  try {
    const response = await fetch(`${HOST_API}/documents/appendFeedback`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${uid}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        feedback: feedback,
        documentId: documentId,
      }),
    });
    if (!response.ok) {
      throw new Error("Appending of Feedback Failed");
    }
    // generate pdf output
    const blob = await response.blob();

    // Extract filename from 'Content-Disposition' header
    const contentDisposition = response.headers.get("Content-Disposition");
    let filename = "document.pdf"; // fallback filename if no header is found

    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="([^"]+)"/);
      if (filenameMatch && filenameMatch[1]) {
        filename = filenameMatch[1];
      }
    }

    // Create a temporary download link
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = filename; // use the extracted filename
    document.body.appendChild(link);
    link.click();

    // Cleanup
    link.remove();
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Error in Appending of Feedback:", error);
  }
};

export const updateFeedbackChoices = async (feedbackChoices) => {
  try {
    const response = await fetch(
      `${HOST_API}/documents/appendFeedbackPreferences`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${uid}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: uid,
          feedbackChoices: feedbackChoices,
        }),
      }
    );
    return response;
  } catch (error) {
    console.error("Error in saving updated Feedback Choices");
  }
};

export const fetchFeedbackChoices = async () => {
  try {
    const response = await fetch(
      `${HOST_API}/documents/appendFeedbackPreferences`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${uid}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const setFeedbackAsModel = async (isModel, id) => {
  try {
    const response = await fetch(
      `${HOST_API}/documents/setFeedbackAsModel/${id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${uid}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          isModel: isModel,
        }),
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
